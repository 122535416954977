/* globals NodeList */
(function () {
	'use strict';

	// forEach polyfill for IE
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = function (callback, thisArg) {
			thisArg = thisArg || window;
			for (var i = 0; i < this.length; i++) {
				callback.call(thisArg, this[i], i, this);
			}
		};
	}

	bravo.swiper.init();

	$('input[type="search"').each(function () {
		const $this = $(this);
		const originalPlaceholderText = $this.attr('placeholder');

		$this.focus(function () {
			$this.attr('placeholder', '');
		});

		$this.blur(function () {
			$this.attr('placeholder', originalPlaceholderText);
		});
	});
})();